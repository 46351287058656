import { Box, Chip, Typography } from '@mui/material'

import { SpecificBedroomField } from '../SpecificBedroomField'
import indexHooks from './hooks/index'
import { CommonModuleProps } from './types.d'

const { floorPlanHooks } = indexHooks

const FloorPlan = ({ currentIndex }: CommonModuleProps) => {
  const { currentData, selectBedroom, selectBathroom, maxSelectBathRoom, bedroomList, bathroomList, onSelectBedroom, onSelectBathroom } =
    floorPlanHooks(currentIndex)

  const specificBedroom = selectBedroom.filter(val => parseInt(val, 10) > 4)
  const hasSpecificBedroom = specificBedroom.length > 0

  return (
    <Box className="w-full !px-5 !py-4 grid grid-cols-1 gap-y-8">
      <Box>
        <Typography className="!font-Averta6 !text-lg">
          {currentIndex + 1}. {currentData?.title}
        </Typography>
        <Typography className="!font-Averta4 !text-sm !mt-2 text-[#75798D]">{currentData?.desc}</Typography>
      </Box>
      {bedroomList?.length ? (
        <Box>
          <Typography className="!font-Averta6 !text-base">Number of bedrooms</Typography>
          <Box className="grid gap-y-2 gap-x-4 mt-4 grid-cols-[repeat(auto-fill,100px)]">
            {bedroomList?.map((item: { key: string; value: string; desc: string; show: boolean }) => (
              <Chip
                onClick={() => onSelectBedroom(item.key)}
                className={`w-[100px] !py-1.5 ${
                  selectBedroom.includes(item?.key) || (item.key === '4+' && hasSpecificBedroom)
                    ? '!border-[#050A22] !bg-[#F7F9FC]'
                    : '!border-[#E0E2EF]'
                } !font-Averta4 !text-sm`}
                label={item.desc}
                variant="outlined"
                key={item.key}
                disabled={item.key === '4+' && hasSpecificBedroom}
              />
            ))}
          </Box>
          {(hasSpecificBedroom || selectBedroom.includes('4+')) && (
            <SpecificBedroomField specificBedroom={specificBedroom} onSelectBedroom={onSelectBedroom} />
          )}
        </Box>
      ) : null}
      {bathroomList?.length ? (
        <Box>
          <Typography className="!font-Averta6 !text-base">Number of bathrooms</Typography>
          <Box className="grid gap-y-2 gap-x-4 mt-4 grid-cols-[repeat(auto-fill,100px)]">
            {bathroomList?.map((item: { key: string; value: string; desc: string; show: boolean }) => (
              <Chip
                disabled={Number(item.key) > Number(maxSelectBathRoom) + 1}
                onClick={() => onSelectBathroom(item.key)}
                className={`w-[100px] !py-1.5 ${
                  selectBathroom.some((val: string) => val === item.key) ? '!border-[#050A22] !bg-[#F7F9FC]' : '!border-[#E0E2EF]'
                } !font-Averta4 !text-sm`}
                label={item.desc}
                variant="outlined"
                key={item.key}
              />
            ))}
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default FloorPlan
