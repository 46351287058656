import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Divider, Typography, Skeleton } from '@mui/material'
import momentTimezone from 'moment-timezone'

import Logo from '../../assets/imgs/surveyLogo.svg'
import TourCompleteImg from '../../assets/imgs/tourComplete.png'
import { GET_TOUR_BY_ID } from '../../graphqls/queries'
import AddressIcon from '../../icons/scheduleTour/address'
import DateIcon from '../../icons/scheduleTour/date'
import HouseIcon from '../../icons/scheduleTour/houseIcon'
import TourTypeIcon from '../../icons/scheduleTour/tourType'
import { ScheduleTourTourType, TourStatusList } from '../scheduleTour/contantsAndType'
import CustomAddToCalendarButton from '../scheduleTour/customAddToCalendarButton'
import { checkIsMobile } from '../../utils/agent'

const IMG_URL = process.env.REACT_APP_IMG_URL
const TourDetailImg = `${IMG_URL}0674ca3028875846.png`
const TourCancelImg = `${IMG_URL}59c95d51933c34c0.png`
const TourHeaderImg = {
  SCHEDULED: TourDetailImg,
  CANCELED: TourCancelImg,
  FINISHED: TourCompleteImg,
}
export default function TourDetail() {
  const { tourId } = useParams()
  const isMobile = checkIsMobile()
  const { data: { getTourById: { data: currentTour = {} as ScheduleTourTourType } = {} } = {}, loading } = useQuery<{
    getTourById: { data: ScheduleTourTourType }
  }>(GET_TOUR_BY_ID, {
    variables: {
      tourId: parseInt(tourId, 10),
    },
  })
  const tourUnitStr = useMemo(() => {
    if (!currentTour) return ''
    return currentTour.units
      ?.map(item => {
        if (item.unitName && item.unitId) {
          return `${item.floorPlanName} - ${item.unitName}`
        }
        return `${item.floorPlanName} - Other Unit`
      })
      .join('; ')
  }, [currentTour])

  if (loading) {
    return (
      <>
        <Skeleton variant="rectangular" height={240} />
        <Skeleton height={200} className="w-[calc(100%-40px)] m-auto" />
        <Skeleton height={200} className="w-[calc(100%-40px)] m-auto" />
      </>
    )
  }

  return (
    <div className="w-full bg-slate-50 min-h-screen">
      {!isMobile && (
        <div className="bg-[#111212] sticky z-20 top-0  px-10 h-16 flex items-center justify-start">
          <img src={Logo} className="h-6" alt="tripalink" />
          <div className="bg-[white] mx-8 h-5 w-[1px] border-box" />
          <Typography className="!font-Averta6 !text-lg !text-[white]">Schedule Tour</Typography>
        </div>
      )}
      <div className="pm-5 schedule-tour-detail lg:w-1/2 mx-auto bg-white lg:min-h-[calc(100vh-64px)]">
        <div className="px-5 py-4 flex flex-col items-center">
          <img className="w-20 h-20" src={TourHeaderImg[currentTour.status]} alt="tour detial" />
          <Typography className="!mt-4 !font-Averta6 !text-lg !text-center"> Your tour is {TourStatusList[currentTour.status]}</Typography>
        </div>
        <div className="w-[calc(100%-40px)] m-auto my-4 rounded-2xl bg-blue-50">
          <div className="px-5 py-3 flex items-start">
            <Typography className="!text-[#75798D] !font-Averta4 !text-base">Tour Host</Typography>
            <Divider orientation="vertical" variant="middle" sx={{ borderColor: '#E0E2EF', margin: '0 8px' }} flexItem />
            <Typography className="!text-[#050A22] !font-Averta6 !text-base">{currentTour.tourAgentName}</Typography>
          </div>
          <div className="bg-white rounded-2xl px-5 py-5" style={{ boxShadow: '0px 2px 5px 0px #0000000A' }}>
            <div className="grid grid-cols-1 gap-y-4">
              <div className="flex items-start justify-start leading-6">
                <DateIcon className="!text-base mt-0.5" />
                &nbsp;
                <div>
                  <Typography className="!font-Averta6 !text-base !text-default">
                    {currentTour.startTime
                      ? `${momentTimezone(currentTour.startTime).tz(currentTour.timeZone).format('h:mm A, dddd, MMM DD, YYYY')}`
                      : '-'}
                  </Typography>
                  <Typography className="!font-Averta4 text-themeColor !text-sm">
                    {currentTour.timeZoneText ? `${currentTour.timeZoneText}` : '-'}
                  </Typography>
                </div>
              </div>
              <div className="flex items-start justify-start leading-6">
                <HouseIcon className="!text-base mt-0.5" />
                &nbsp;
                {Array.isArray(currentTour.units) && currentTour.units.length ? (
                  <div className="flex flex-col items-start justify-center">
                    <Typography className="text-themeColor !text-base !font-Averta6">{currentTour?.propertyName}</Typography>
                    <Typography className="text-themeColor !text-sm !font-Averta4">{tourUnitStr}</Typography>
                  </div>
                ) : (
                  <Typography className="text-themeColor !text-base !font-Averta6">{currentTour?.propertyName}</Typography>
                )}
              </div>

              <div className="flex items-start justify-start leading-6">
                <AddressIcon className="!text-base mt-0.5" />
                &nbsp;
                <Typography className="!font-Averta6 !text-base text-themeColor">{currentTour.propertyAddress || '-'}</Typography>
              </div>
              <div className="flex items-start justify-start leading-6">
                <TourTypeIcon className="!text-base mt-0.5" />
                &nbsp;
                <Typography className="!font-Averta6 !text-base text-themeColor">
                  {currentTour?.tourType ? currentTour.tourType : '-'}
                </Typography>
              </div>
              {currentTour.preference && <Divider orientation="horizontal" />}

              {Array.isArray(currentTour.preference) && currentTour.preference.length > 0 && (
                <div className="flex flex-col items-start justify-center">
                  <Typography className="!text-gray-550 !text-sm !font-Averta4">Preference</Typography>
                  <Typography className="!text-default !text-base !font-Averta6">{currentTour.preference.join('; ')}</Typography>
                </div>
              )}
            </div>
          </div>
        </div>
        {currentTour.status === 'SCHEDULED' && (
          <div className="w-full px-4 mb-1">
            <CustomAddToCalendarButton data={{ ...currentTour, prospectUserName: currentTour.prospectName || '' }} />
          </div>
        )}
      </div>
    </div>
  )
}
