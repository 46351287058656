import { createContext } from 'react'

import { DataCollectProps, ModuleItemProps } from './types.d'

interface FormContextProps {
  id: string
  sign: string
  version: string
  isPc: boolean
  allStep: number
  currentStep: number
  setCurrentStep: ((val: number) => void) | null
  isSubmit: boolean
  canSubmit: boolean
  isCanNext: boolean
  moduleList: ModuleItemProps[]
  dataCollect: DataCollectProps[]
  setDataCollect: ((val: []) => void) | null
  onSubmit: (({ submit }: { submit: boolean }) => void) | null
  showHeaderBack: boolean
  loadingPreference: boolean
  saveLoading: boolean
  updateDataCollectSubmitValueFunction: ((index: number, submitValueIndex: number, value: any) => void) | null
  mergeDataCollectFunction: ((index: number, data: any) => void) | null
}
const FormContext = createContext<FormContextProps>({
  id: '',
  sign: '',
  version: '',
  isPc: false,
  allStep: 0,
  currentStep: 0,
  setCurrentStep: null,
  isSubmit: false,
  canSubmit: false,
  isCanNext: false,
  moduleList: [],
  dataCollect: [],
  setDataCollect: null,
  onSubmit: null,
  showHeaderBack: false,
  loadingPreference: false,
  saveLoading: false,
  updateDataCollectSubmitValueFunction: null,
  mergeDataCollectFunction: null,
})

export default FormContext
