import React from 'react'
import { Typography } from '@mui/material'
import ScheduleTourHeader from './scheduleTourHeader'
import VarsitySelectedForm from './varsitySelectedForm'
import ScheduleTourWayAndTime from './scheduleTourWayAndTime'
import { PageTypeType, AvailableFloorPlan, ScheduleTourPropertyType } from './contantsAndType'
import SubmitTourPc from './SubmitTourPc'
import ErrorSnackbar from '../errorSnackbar'

interface SubmitVarsityTourPageProps {
  isMobile: boolean
  fixedDom: boolean
  pageType: PageTypeType
  propertyList: ScheduleTourPropertyType[]
  isReschedule: boolean
  onFooterBack: () => void
  selectedProperty?: ScheduleTourPropertyType
  isCentralizedStage: boolean
  prospectId: string
  tourTypeList: { key: string; name: string; desc: string }[]
  tourType: string
  selfGuidTourUnits: { floorPlanName: string; modelUnitId: number; modelUnitName: string; units: { unitId: number; unitName: string }[] }[]
  agentInfo: { id: string; name: string }
  selectedPropertyTimeZone: { displayText: string; timeZone: string }
  setTourType: (val: string) => void
  onBackNewUserId: (val: { newAdminUserId: string; newAdminUserName: string }) => void
  setErrorMessageOfAddingTour: (val: { open: boolean; message: string }) => void
  setSelectedAgentTime: (val?: { time: string; startSecond: string }) => void
  setIsNoAvailableDay: (val: boolean) => void
  errorMessageOfAddingTour: { open: boolean; message: string }
  setPageType: (val: PageTypeType) => void
  selectedFloorPlans: AvailableFloorPlan[]
  onPropertyChange: (id: string) => void
}

const SubmitVarsityTourPage = ({
  isMobile,
  fixedDom,
  pageType,
  propertyList,
  isReschedule,
  onFooterBack,
  selectedProperty,
  isCentralizedStage,
  prospectId,
  tourTypeList,
  tourType,
  selfGuidTourUnits,
  agentInfo,

  selectedPropertyTimeZone,
  setTourType,
  onBackNewUserId,
  setErrorMessageOfAddingTour,
  setSelectedAgentTime,
  setIsNoAvailableDay,
  errorMessageOfAddingTour,
  setPageType,
  selectedFloorPlans,
  onPropertyChange,
}: SubmitVarsityTourPageProps) => {
  return (
    <div className="lg:min-h-[calc(100vh-125px)]">
      <div
        className="w-full h-48 lg:h-[261px] bg-gray-200"
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundImage: selectedProperty
            ? `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url('${selectedProperty.headPicUrl}')`
            : '',
        }}
      />
      <div
        className="w-[calc(100%-40px)] m-auto mt-[-40px] py-4 rounded-t-xl flex flex-col items-center"
        style={{ background: 'linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%)' }}
      >
        <ScheduleTourHeader
          fixedDom={fixedDom}
          theme="black"
          pageType={pageType}
          list={propertyList}
          showMenu={propertyList.length > 1}
          onChange={onPropertyChange}
          selected={selectedProperty?.id ?? ''}
        />
        {selectedProperty?.address && <Typography className="!font-Averta4 !text-sm !text-center">{selectedProperty.address}</Typography>}
      </div>

      <div className="w-full pb-[95px]">
        {isMobile ? (
          <div className=" pb-[95px]">
            <VarsitySelectedForm isReschedule={isReschedule} selectedFloorPlans={selectedFloorPlans} setPageType={setPageType} />
            <ScheduleTourWayAndTime
              selectedFloorPlans={selectedFloorPlans}
              isCentralizedStage={isCentralizedStage}
              typeList={tourTypeList}
              type={tourType}
              prospectId={prospectId}
              timeZoneInfo={selectedPropertyTimeZone}
              selectedProperty={selectedProperty}
              onChangeType={setTourType}
              agentInfo={agentInfo}
              isReschedule={isReschedule}
              onChangeTime={setSelectedAgentTime}
              onBackNewUserId={onBackNewUserId}
              onNoAvailableDay={() => setIsNoAvailableDay(true)}
            />
            <ErrorSnackbar setAddTourError={setErrorMessageOfAddingTour} addTourError={errorMessageOfAddingTour} />
          </div>
        ) : (
          <SubmitTourPc
            isVarsityProperty
            property={{
              name: selectedProperty ? selectedProperty.aliasName || selectedProperty.name : 'Unknown',
              address: selectedProperty?.address ?? 'unknown',
              propertyId: selectedProperty?.id ?? '',
            }}
            isCentralizedStage={isCentralizedStage}
            selfGuidTourUnits={selfGuidTourUnits}
            selectedFloorPlans={selectedFloorPlans}
            prospectId={prospectId}
            selectedProperty={selectedProperty}
            onChangeTime={setSelectedAgentTime}
            agentInfo={agentInfo}
            onChangeType={setTourType}
            timeZoneInfo={selectedPropertyTimeZone}
            type={tourType}
            typeList={tourTypeList}
            setPageType={setPageType}
            isReschedule={isReschedule}
            onBackNewUserId={onBackNewUserId}
            onNoAvailableDay={() => setIsNoAvailableDay(true)}
            setAddTourError={setErrorMessageOfAddingTour}
            addTourError={errorMessageOfAddingTour}
          />
        )}
      </div>
    </div>
  )
}

export default SubmitVarsityTourPage
