import { Autocomplete, Chip, TextField } from '@mui/material'

type Props = {
  specificBedroom: string[]
  onSelectBedroom: (val: string) => void
}

export function SpecificBedroomField({ specificBedroom, onSelectBedroom }: Props) {
  return (
    <Autocomplete
      className="w-full mt-2"
      clearIcon={false}
      multiple
      freeSolo
      options={['5', '6', '7', '8', '9', '10', '11']}
      value={specificBedroom}
      onChange={(event, newValues) => {
        const newValue = newValues.find(val => !specificBedroom.includes(val))
        const deleteValue = specificBedroom.find(val => !newValues.includes(val))
        if (newValue) {
          onSelectBedroom(newValue)
        }
        if (deleteValue) {
          onSelectBedroom(deleteValue)
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            key={index}
            sx={{
              height: '24px',
              borderRadius: '12px',
              '& .MuiChip-label': {
                padding: '0 8px',
              },
              '& .MuiChip-deleteIcon': {
                fontSize: '16px',
                width: '16px',
                height: '16px',
              },
            }}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Specify (optional)"
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '38px',
              padding: 0,
              '& fieldset': {
                borderRadius: '8px',
                borderColor: '#e0e2ef',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#1f2937',
                borderWidth: '1px',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#1f2937',
            },
          }}
        />
      )}
    />
  )
}
