import React, { FC, Key, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Box, Button, Dialog, IconButton, Skeleton, Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Statsig, useGate } from 'statsig-react'
import { floor, isEmpty } from 'lodash'
import RentyLogo from '../../assets/imgs/rentyWhiteIcon.svg'
import Logo from '../../assets/imgs/surveyLogo.svg'
import warning from '../../assets/imgs/warning.svg'
import {
  ADD_TOUR_FOR_USER,
  SAVE_SCHEDULE_TOUR_SELECT_UNIT,
  SAVE_PROSPECT_PREFERENCE_TAG,
  SAVE_URL_TTL_LOG,
  TOUR_REASSIGN,
} from '../../graphqls/mutations'
import {
  QUERY_CHECKED_SHELTER,
  QUERY_INIT_TOUR_INFO_FOR_CUSTOMER,
  QUERY_PROPERTY_BY_QUESTIONNAIRE_ID_V1,
  QUERY_PROPERTY_TIME_ZONE,
  QUERY_PROPERTY_TOUR_AGENT,
  QUERY_TOUR_LIST_BY_PROSPECT_ID,
} from '../../graphqls/queries'
import QuestionCircle from '../../icons/questionCircle'
import { initPageConfig } from '../../utils'
import { checkIsMobile } from '../../utils/agent'
import {
  AvailableFloorPlan,
  PageTypeType,
  PreferenceList,
  PreSelectedUnitInfo,
  ScheduleTourPropertyType,
  ScheduleTourTourType,
  SelectedUnitsType,
  MAX_SELECTED_UNIT,
  RentalTypeEnum,
} from './contantsAndType'
import EndStep from './EndStep'
import ScheduleTourFloorPlan from './scheduleTourFloorPlan'
import ScheduleTourDetail from './scheduleTourDetail'
import ScheduleTourFooter from './scheduleTourFooter'
import ScheduleTourFooterPc from './scheduleTourFooterPc'
import ScheduleTourHeader from './scheduleTourHeader'
import ScheduleTourUnit from './scheduleTourUnit'
import SubmitTourPage from './submitTourPage'
import SubmitVarsityTourPage from './submitVarsityTourPage'
import {
  buildTourCoLivingsEventMetadata,
  calcSelectedCount,
  calcTimeOverlap,
  getStatsigProperties,
  getSubmitPropertyInfo,
  refactSelectedUnitTime,
} from './utils'

let logInterval: NodeJS.Timer | null = null
let errorMsgTimer: NodeJS.Timer
const isMobile = checkIsMobile()
const HEADER_HEIGHT = 64

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }),
)

const ScheduleTour: FC = () => {
  const { questionnaireId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const version = searchParams.get('version')
  const bedroomValue = searchParams.get('bedroomNum') || 'ALL'
  const propertyId = searchParams.get('propertyId') || null
  const isTourDisabled = useGate('varsity_tour_enabled')

  const [isNoAvailableDay, setIsNoAvailableDay] = useState<boolean | undefined>(undefined)
  const [pageType, setPageType] = useState<PageTypeType>('unit')
  const [selectedPreferences, setSelectedPreferences] = useState<string[]>([])
  const [tourTypeList, setTourTypeList] = useState<{ key: string; name: string; desc: string }[]>([])
  const [prospectId, setProspectId] = useState('')
  const [prospectUserName, setProspectUserName] = useState('')
  const [propertyList, setPropertyList] = useState<ScheduleTourPropertyType[]>([])
  const [selectedProperty, setSelectedProperty] = useState<ScheduleTourPropertyType>()
  const [tourList, setTourList] = useState<ScheduleTourTourType[]>([])
  const [tourType, setTourType] = useState('')
  const [selfGuidTourUnits, setSelfGuidTourUnits] = useState<
    { floorPlanName: string; modelUnitId: number; modelUnitName: string; units: { unitId: number; unitName: string }[] }[]
  >([])

  const [selectedUnits, setSelectedUnits] = useState<SelectedUnitsType>(new Map())
  const [selectedFloorPlans, setSelectedFloorPlans] = useState<AvailableFloorPlan[]>([])
  const [selectedAgentTime, setSelectedAgentTime] = useState<{ time: string; startSecond: string }>()
  const [newAdminUserInfo, setNewAdminUserInfo] = useState<{ newAdminUserId: string; newAdminUserName: string }>()
  const [isAnotherSchedule, setIsAnotherSchedule] = useState(false)

  const [fixedDom, setFixedDom] = useState(false)
  const [selectedPropertyTimeZone, setSelectedPropertyTimeZone] = useState<{ displayText: string; timeZone: string }>({
    displayText: '',
    timeZone: '',
  })
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [checkUnitTimeDialog, setCheckUnitTimeDialog] = useState(false)
  const [isReschedule, setRescheduleStatus] = useState(false)
  const [scheduledUnitIds, setScheduledUnitIds] = useState([''])

  const [currTourId, setCurrTourId] = useState(0)
  const [isCentralizedStage, setIsCentralizedStage] = useState(false)
  const [openErrorMsg, setOpenErrorMsg] = useState(false)
  const [saveUrlTtlLogApi] = useMutation(SAVE_URL_TTL_LOG, {
    onError() {
      // do nothing
    },
    variables: { input: { questionnaireId, source: 'TOUR' } },
  })
  const [errorMessageOfAddingTour, setErrorMessageOfAddingTour] = useState({
    open: false,
    message: '',
  })

  const isVarsityProperty = useMemo(() => {
    return selectedProperty?.rentalType === RentalTypeEnum.STUDENT_FLATSHARE
  }, [selectedProperty])

  const [checkedShelter] = useLazyQuery(QUERY_CHECKED_SHELTER, {
    fetchPolicy: 'network-only',
    onCompleted({ queryCheckedShelter }) {
      const { data } = queryCheckedShelter || {}
      // 获取第一条数据，查看当前选择的propertyId
      const [shelterChecked] = data || []

      if (!isEmpty(shelterChecked)) {
        if (Array.isArray(propertyList) && propertyList.length > 0) {
          const currentProperty = propertyList.find(item => item.id === String(shelterChecked?.propertyId)) as ScheduleTourPropertyType
          if (!currentProperty) {
            setSelectedProperty(propertyList?.[0])
            return
          }
          setSelectedProperty(currentProperty)
          const { floorPlanModeUnit } = currentProperty || {}

          if (currentProperty?.rentalType === RentalTypeEnum.STUDENT_FLATSHARE) {
            const defaultSelectedFloorPlan: any = data?.map((item: { floorPlanId: number; floorPlanName: string }) => ({
              floorPlanId: item?.floorPlanId,
              floorPlanName: item?.floorPlanName,
            }))
            setSelectedFloorPlans(defaultSelectedFloorPlan)
          } else {
            const [units] = floorPlanModeUnit || []
            const { availableUnits } = units || []
            const preSelected = data.reduce((pre: SelectedUnitsType, shelter: PreSelectedUnitInfo) => {
              availableUnits.forEach((unit: any) => {
                const { floorPlanIndex, name, id: unitId, rooms } = unit || {}

                if (String(shelter.unitId) === String(unitId)) {
                  pre.set(unit.name, {
                    unitsInfo: {
                      floorPlanIndex,
                      unitName: name,
                      unitTitle: name,
                      unitId: Number(unitId),
                      rooms: shelter.roomIds.map(roomId => {
                        const room = rooms.find((r: { id: any }) => String(r.id) === String(roomId))

                        return {
                          id: Number(room?.id),
                          name: room?.name || '',
                          unitId: Number(room?.unitId),
                          availableStatus: room?.availableStatus || 0,
                          availableStartTime: room?.availableStartTime || 0,
                          preTourStartDate: room?.preTourStartDate || 0,
                          preTourEndDate: room?.preTourEndDate || 0,
                        }
                      }),
                    },
                  })
                }
              })

              return pre
            }, new Map())
            setSelectedUnits(preSelected)
          }
        }
      } else {
        setSelectedProperty(propertyList?.[0])
      }
    },
  })

  const [saveProspectPreferenceTag] = useMutation(SAVE_PROSPECT_PREFERENCE_TAG)

  const [queryPropertyTourAgentApi, { fetchMore: fetchMoreQueryPropertyTourAgentApi, data }] = useLazyQuery(QUERY_PROPERTY_TOUR_AGENT, {
    onError() {
      // nothing
    },
  })
  const agentInfo = data?.queryPropertyTourAgent?.adminUsers?.map(
    ({ adminUserId, adminUserName }: { adminUserId: number; adminUserName: string }) => ({ id: adminUserId, name: adminUserName }),
  )?.[0]
  const [queryInitTourInfoForCustomerApi] = useLazyQuery(QUERY_INIT_TOUR_INFO_FOR_CUSTOMER, {
    onCompleted({ queryInitTourInfoForCustomer }) {
      const { data } = queryInitTourInfoForCustomer || {}
      const { tourType: tourTypeResult, selfGuidedUnit = [], prospectFirstName, prospectLastName } = data || {}
      setProspectUserName(`${prospectFirstName} ${prospectLastName}`)
      setTourTypeList(tourTypeResult || [])
      setTourType((tourTypeResult || [])[0]?.key ?? '')
      setSelfGuidTourUnits(selfGuidedUnit)
    },
    onError() {
      // nothing
    },
  })
  const [queryPropertyTimeZoneApi] = useLazyQuery(QUERY_PROPERTY_TIME_ZONE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const { queryPropertyTimeZone } = data || {}
      const { displayText, timeZone } = queryPropertyTimeZone || {}
      setSelectedPropertyTimeZone({ displayText, timeZone })
    },
    onError() {
      setSelectedPropertyTimeZone({ displayText: '', timeZone: '' })
    },
  })

  const [queryTourListByProspectIdApi, { loading: tourListLoading }] = useLazyQuery(QUERY_TOUR_LIST_BY_PROSPECT_ID, {
    onCompleted(res) {
      const { queryTourListByProspectId } = res || {}
      const { data } = queryTourListByProspectId || {}
      const curTourList = (Array.isArray(data) ? data : []).filter(item => item.status !== 'CANCELED')
      setTourList(curTourList)
      if (curTourList.some(tour => selectedProperty && String(tour.propertyId) === String(selectedProperty.id))) {
        Statsig.logEvent('schedule_tour_tour_detail', window.location.href, {
          pageType,
          questionnaireId,
          prospectId,
          propertyId: selectedProperty?.id || '',
        })
        if (!isAnotherSchedule) {
          setPageType('tourInfo')
        }
      }
    },
    onError() {
      // something bad
    },
  })

  const { loading, refetch } = useQuery(QUERY_PROPERTY_BY_QUESTIONNAIRE_ID_V1, {
    notifyOnNetworkStatusChange: true,
    variables: {
      questionnaireId,
    },
    async onCompleted(data) {
      const { searchPropertyByQuestionnaireIdV1 } = data || {}
      if (!searchPropertyByQuestionnaireIdV1) {
        return
      }

      const { propertyResult: properties, prospectId: currentProspectId } = searchPropertyByQuestionnaireIdV1

      const propertyResult = refactSelectedUnitTime(properties)
      if (!isReschedule) {
        queryTourListByProspectIdApi({
          variables: { prospectId: currentProspectId, properties, propertyId: Number(propertyId) || Number(properties[0]?.id) },
        })
      }

      setProspectId(currentProspectId || '')
      checkedShelter({
        variables: {
          prospectId: Number(currentProspectId),
        },
      })
      setPropertyList(Array.isArray(propertyResult) ? propertyResult : [])
      Statsig.logEvent('schedule_tour_recommend_property', window.location.href, {
        questionnaireId,
        prospectId: currentProspectId,
        properties: JSON.stringify(getStatsigProperties(propertyResult)),
      })

      const variables = { propertyId: Number(propertyResult[0].id), prospectId: currentProspectId }
      const propertyTimeZoneResponse = await queryPropertyTimeZoneApi({
        variables: {
          input: {
            propertyId: Number(propertyResult[0].id),
            prospectId: currentProspectId,
          },
        },
      })
      const { isCentralizedLeasing: isCentralizedStage } = propertyTimeZoneResponse?.data?.queryPropertyTimeZone || {}
      setIsCentralizedStage(isCentralizedStage)
      if (!isCentralizedStage) {
        queryPropertyTourAgentApi({
          variables,
        })
      }

      queryInitTourInfoForCustomerApi({
        variables,
      })
      if (Array.isArray(propertyResult) && propertyResult.length > 0 && currentProspectId) {
        Statsig.logEvent('schedule_tour_default_select_property', window.location.href, {
          questionnaireId,
          pageType,
          prospectId: currentProspectId,
          propertyId: propertyResult[0].id,
        })
      }
    },
    onError() {
      Statsig.logEvent('schedule_tour_query_property_error', window.location.href, { questionnaireId })
      // something bad
    },
  })
  const [addTourForUserApi, { loading: addTourForUserLoading }] = useMutation(ADD_TOUR_FOR_USER, {
    async onCompleted(data) {
      const { addTourForUser } = data || {}
      const { ret, tourId, msg } = addTourForUser || {}
      if (ret && !!tourId) {
        await queryTourListByProspectIdApi({ variables: { prospectId }, fetchPolicy: 'network-only' }).catch()
        setPageType('tourInfo')
      } else {
        setErrorMessageOfAddingTour(() => ({
          open: true,
          message: msg,
        }))
      }
    },
    onError() {
      // do nothing
    },
  })

  const [tourReassign, { loading: tourReassignLoading }] = useMutation(TOUR_REASSIGN, {
    async onCompleted(data) {
      const { tourReassign } = data || {}
      const { ret } = tourReassign || {}
      if (ret) {
        await queryTourListByProspectIdApi({ variables: { prospectId }, fetchPolicy: 'network-only' }).catch()
        setPageType('tourInfo')
        setIsAnotherSchedule(false)
      }
    },
    onError() {
      // do nothing
    },
  })

  const headerList = useMemo(() => propertyList.map(item => ({ id: item.id, name: item.aliasName || item.name })), [propertyList])
  const selectedCount = useMemo(
    () => (isVarsityProperty ? selectedFloorPlans?.length : calcSelectedCount(selectedUnits)),
    [selectedUnits, selectedFloorPlans],
  )
  const disableNext = useMemo(() => {
    if (pageType === 'unit') return selectedCount === 0
    if (pageType === 'submitTour') return tourType === '' || !selectedAgentTime?.startSecond
    return true
  }, [pageType, selectedPreferences, tourType, selectedCount, selectedAgentTime])

  const isCoLivingProperty = useMemo(() => selectedProperty?.coLivingStatus === 'CO-LIVING', [selectedProperty?.coLivingStatus])

  const unitList = useMemo(() => {
    const { floorPlanModeUnit } = selectedProperty || {}
    const currentFloorPlan = floorPlanModeUnit?.find(floor => floor.bedroomOption.value === bedroomValue)
    return currentFloorPlan?.availableUnits || []
  }, [bedroomValue, selectedProperty?.id])

  const floorPlanList = useMemo(() => {
    const { floorPlanModeUnit } = selectedProperty || {}
    const currentFloorPlan = floorPlanModeUnit?.find(floor => floor.bedroomOption.value === 'ALL')
    return currentFloorPlan?.availableUnits || []
  }, [selectedProperty?.id])

  const checkSelectedUnitTime = () => {
    const { isOverlap, min, max } = calcTimeOverlap(selectedUnits)
    const selectValueObj = [...selectedUnits.entries()].reduce((obj, [key, value]) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = value
      return obj
    }, {} as Record<string, unknown>)
    if (!isOverlap) {
      Statsig.logEvent('schedule_select_units_time_overlap', window.location.href, {
        isOverlap: isOverlap.toString(),
        min: Number(min).toString(),
        max: Number(max).toString(),
        selectValueObj: JSON.stringify(selectValueObj),
        prospectId,
        propertyId: selectedProperty?.id ?? '',
      })
      setCheckUnitTimeDialog(true)
      return
    }
    if (!selectedAgentTime) return
    if (min > Number(selectedAgentTime.startSecond) * 1000 || max < Number(selectedAgentTime.startSecond) * 1000) {
      Statsig.logEvent('schedule_select_units_time_overlap', window.location.href, {
        isOverlap: isOverlap.toString(),
        min: Number(min).toString(),
        max: Number(max).toString(),
        selectValueObj: JSON.stringify(selectValueObj),
        selectedAgentTime: selectedAgentTime.startSecond,
        prospectId,
        propertyId: selectedProperty?.id ?? '',
      })
      setCheckUnitTimeDialog(true)
    } else {
      onSubmitTour()
    }
  }

  const afterCloseCheckUnitTimeDialog = (submit: boolean) => {
    Statsig.logEvent('schedule_units_time_dialog', window.location.href, {
      submit: submit.toString(),
      prospectId,
      propertyId: selectedProperty?.id ?? '',
    })
    setCheckUnitTimeDialog(false)
    if (submit) {
      onSubmitTour()
    }
  }
  const [saveLeasingWantTour] = useMutation(SAVE_SCHEDULE_TOUR_SELECT_UNIT)

  const saveSelectedUnitsOrFloorPlanToLeasing = () => {
    const propertyInfo = getSubmitPropertyInfo({
      selectedUnits,
      selectedProperty,
      selectedFloorPlans,
    })

    const input = {
      aiLeasingWantTourList: {
        tourListJson: propertyInfo,
        propertyId: selectedProperty?.id,
        prospectId,
        isColiving: true,
      },
    }
    saveLeasingWantTour({
      variables: {
        input,
      },
    })
  }
  const onFooterNext = useCallback(() => {
    let nextType: PageTypeType = pageType
    const logs = {
      pageType,
      questionnaireId,
      prospectId: selectedProperty?.id || '',
    }

    Statsig.logEvent(
      'schedule_tour_recommendation_apartments',
      window.location.href,
      buildTourCoLivingsEventMetadata(prospectId, selectedProperty, selectedUnits, selectedFloorPlans) as Record<string, string>,
    )

    if (pageType === 'unit') {
      nextType = 'submitTour'
      setPageType(nextType)
      saveSelectedUnitsOrFloorPlanToLeasing()
      Statsig.logEvent('schedule_tour_footer_next', window.location.href, logs)
    }
    if (pageType === 'submitTour') {
      checkSelectedUnitTime()
    }
  }, [prospectId, selectedProperty, selectedUnits, pageType, selectedAgentTime, isCentralizedStage, selectedFloorPlans])

  const onFooterBack = useCallback(() => {
    let nextType: PageTypeType = pageType
    setNewAdminUserInfo({ newAdminUserId: '', newAdminUserName: '' })
    if (pageType === 'submitTour' && isReschedule) {
      setPageType('tourInfo')
      setIsAnotherSchedule(false)
      return
    }

    if (pageType === 'submitTour') {
      setSelectedAgentTime(undefined)
      nextType = 'unit'
    }

    Statsig.logEvent('schedule_tour_footer_back', window.location.href, {
      pageType,
      nextType,
      questionnaireId,
      propertyId: selectedProperty?.id || '',
      prospectId,
    })
    if (!isMobile) {
      // pc allow link unit
      nextType = 'unit'
    }
    setPageType(nextType)
  }, [pageType])
  const onSubmitTour = () => {
    if ((!isCentralizedStage && !agentInfo?.id) || !selectedProperty || !selectedAgentTime || !selectedPropertyTimeZone?.timeZone) {
      return
    }

    const propertyInfo = getSubmitPropertyInfo({
      selectedUnits,
      selectedProperty,
      selectedFloorPlans,
    })
    const units = [...selectedUnits.values()].map(item => item.unitsInfo.unitId)
    const input = {
      prospectId: Number(prospectId),
      propertyId: Number(selectedProperty.id),
      tourAgentId: newAdminUserInfo?.newAdminUserId, // agentInfo.id,
      tourAgentName: newAdminUserInfo?.newAdminUserName,
      time: selectedAgentTime?.time,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeStartSecond: Number(selectedAgentTime?.startSecond),
      propertyTimeZone: selectedPropertyTimeZone?.timeZone,
      tourType,
      preference: selectedPreferences,
      propertyInfo,
      unitsInfo: units?.length ? [{ units }] : undefined,
      questionnaireTourDetailURL: window.location.href,
    }

    const reScheduleInput = {
      id: currTourId,
      adminUserId: newAdminUserInfo?.newAdminUserId,
      time: selectedAgentTime?.time,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      propertyTimeZone: selectedPropertyTimeZone?.timeZone,
      timeStartSecond: Number(selectedAgentTime?.startSecond),
      source: 'USER',
    }
    const preferenceTagList: { mainTag: { tagCode: string; tagName: string } }[] = []
    selectedPreferences?.forEach(item => {
      const findItem = PreferenceList?.find(v => v?.label === item)
      if (findItem) {
        preferenceTagList.push({
          mainTag: {
            tagCode: findItem?.value,
            tagName: findItem?.label,
          },
        })
      }
    })

    saveProspectPreferenceTag({
      variables: {
        input: {
          prospectId: Number(prospectId),
          tagList: preferenceTagList,
        },
      },
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Statsig.logEvent('schedule_tour_submit_tour', window.location.href, input as any)

    if (isReschedule && currTourId) {
      tourReassign({
        variables: {
          input: reScheduleInput,
        },
      }).finally(() => {
        if (logInterval) {
          clearInterval(logInterval)
        }
      })
    } else {
      addTourForUserApi({
        variables: {
          input,
        },
      }).finally(() => {
        if (logInterval) {
          clearInterval(logInterval)
        }
      })
    }
  }

  const changeProperty = async (property: ScheduleTourPropertyType) => {
    if (!property) return
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    setSearchParams((prevSearchParams: string | string[][] | Record<string, string> | URLSearchParams | undefined) => {
      const newSearchParams = new URLSearchParams(prevSearchParams)
      newSearchParams.set('bedroomNum', '')
      return newSearchParams
    })
    setSelectedAgentTime(undefined)
    setSelectedPreferences([])
    setSelectedUnits(new Map())
    setTourType('')
    setSelectedProperty(property)
    const propertyTimeZoneResponse = await queryPropertyTimeZoneApi({
      variables: {
        input: {
          propertyId: Number(property.id),
          prospectId,
        },
      },
    })
    const { isCentralizedLeasing: isCentralizedStage } = propertyTimeZoneResponse?.data?.queryPropertyTimeZone || {}
    setIsCentralizedStage(isCentralizedStage)
    const variables = { propertyId: Number(property.id), prospectId }
    queryInitTourInfoForCustomerApi({ variables })
    if (!isCentralizedStage) {
      queryPropertyTourAgentApi({
        variables,
      })
    }

    if (tourList.some(tour => property && String(tour.propertyId) === String(property.id))) {
      setPageType('tourInfo')
      setIsAnotherSchedule(false)
    } else {
      setPageType('unit')
    }
  }
  const checkLogInterval = () => {
    if (document.visibilityState === 'hidden' && logInterval) {
      clearInterval(logInterval)
    }
    if (document.visibilityState === 'visible') {
      saveUrlTtlLogApi()
      if (logInterval) clearInterval(logInterval)
      logInterval = setInterval(saveUrlTtlLogApi, 10000)
    }
  }
  const onPropertyChange = (id: string) => {
    const target = propertyList.find(item => item.id === id)
    if (!target) return
    changeProperty(target)
  }
  const onSelectUnitChange = (map: SelectedUnitsType) => {
    const changeCount = calcSelectedCount(map)
    if (changeCount <= MAX_SELECTED_UNIT) {
      const selectValueObj = [...map.entries()].reduce((obj, [key, value]) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = value
        return obj
      }, {} as Record<string, unknown>)
      Statsig.logEvent('schedule_tour_select_unit', window.location.href, {
        questionnaireId,
        prospectId,
        selectUnits: JSON.stringify(selectValueObj),
        pageType,
      })
      setSelectedUnits(map)
    } else {
      setOpenErrorMsg(true)
      clearTimeout(errorMsgTimer)
      errorMsgTimer = setTimeout(() => {
        setOpenErrorMsg(false)
      }, 2000)
    }
  }

  const onSelectFloorPlanChange = (values: AvailableFloorPlan[]) => {
    const changeCount = values?.length
    if (changeCount <= MAX_SELECTED_UNIT) {
      Statsig.logEvent('schedule_tour_select_floor_plan', window.location.href, {
        questionnaireId,
        prospectId,
        selectUnits: JSON.stringify(values),
        pageType,
      })
      setSelectedFloorPlans(values)
    } else {
      setOpenErrorMsg(true)
      clearTimeout(errorMsgTimer)
      errorMsgTimer = setTimeout(() => {
        setOpenErrorMsg(false)
      }, 2000)
    }
  }

  const onClear = () => {
    setSelectedUnits(new Map())
    setSelectedFloorPlans([])
  }

  const onSearchUnits = (event: MouseEvent<HTMLButtonElement>) => {
    const {
      currentTarget: {
        dataset: { bd },
      },
    } = event

    setSearchParams((prevSearchParams: string | string[][] | Record<string, string> | URLSearchParams | undefined) => {
      const newSearchParams = new URLSearchParams(prevSearchParams)
      newSearchParams.set('bedroomNum', bd || '')

      return newSearchParams
    })
  }

  const filterOptions = useMemo(() => {
    if (selectedProperty?.id) {
      return selectedProperty.floorPlanModeUnit.map(floor => floor.bedroomOption)
    }

    return []
  }, [selectedProperty?.id])

  useEffect(() => {
    if (Number(version)) {
      initPageConfig(Number(version))
    }
    if (logInterval) {
      clearInterval(logInterval)
    }
    saveUrlTtlLogApi()
    logInterval = setInterval(saveUrlTtlLogApi, 10000)
    document.addEventListener('visibilitychange', checkLogInterval)
    Statsig.logEvent('schedule_tour_PV_UV', window.location.href, {
      questionnaireId,
      propertyId: selectedProperty?.id || '',
    })
    return () => {
      if (logInterval) clearInterval(logInterval)
      document.removeEventListener('visibilitychange', checkLogInterval)
    }
  }, [])
  const onWinScroll = () => {
    setFixedDom(window.scrollY > HEADER_HEIGHT)
  }
  const onBackNewUserId = (data: { newAdminUserId: string; newAdminUserName: string }) => {
    setNewAdminUserInfo(data)
  }
  const onAnotherCallback = () => {
    setIsAnotherSchedule(true)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', onWinScroll)
    return () => {
      window.removeEventListener('scroll', onWinScroll)
    }
  }, [])

  useEffect(() => {
    Statsig.updateUser({
      custom: {
        propertyId: selectedProperty?.id,
      },
    })
  }, [selectedProperty])

  useEffect(() => {
    if (scheduledUnitIds || isAnotherSchedule) {
      refetch()
    }
  }, [scheduledUnitIds, isAnotherSchedule])

  if (!questionnaireId) return null
  if (loading || tourListLoading) {
    return (
      <>
        <Skeleton variant="rectangular" height={240} />
        <Skeleton height={200} className="w-[calc(100%-40px)] m-auto" />
        <Skeleton variant="rectangular" className="w-full fixed bottom-0 !h-[90px]" />
      </>
    )
  }

  const getFooter = () =>
    isMobile ? (
      <ScheduleTourFooter
        onBack={onFooterBack}
        onClick={onFooterNext}
        disabled={disableNext || tourListLoading || addTourForUserLoading || tourReassignLoading}
        type={pageType}
        nextLoading={tourListLoading || addTourForUserLoading || tourReassignLoading}
        isReschedule={isReschedule}
        selectedCount={selectedCount}
        maxUnit={MAX_SELECTED_UNIT}
      />
    ) : (
      <ScheduleTourFooterPc
        onBack={onFooterBack}
        onClick={onFooterNext}
        disabled={disableNext || tourListLoading || addTourForUserLoading || tourReassignLoading}
        type={pageType}
        nextLoading={tourListLoading || addTourForUserLoading || tourReassignLoading}
        isReschedule={isReschedule}
      />
    )

  const body = (
    <div className="w-full  min-h-screen lg:min-h-[calc(100vh-64px)] lg:w-1/2  m-auto bg-[white]">
      {!isMobile && openErrorMsg ? (
        <div className="fixed m-auto z-40 -translate-x-1/2 left-[50%] top-[74px] text-[white] text-sm  font-Averta4  rounded bg-[rgba(13,19,51,0.8)]  px-4 py-2.5 flex items-center">
          <img src={warning} alt="" className="mr-1" />
          <span>You can only select up to 4 for your tour</span>
        </div>
      ) : null}
      {pageType === 'unit' && (
        <div className="lg:min-h-[calc(100vh-125px)]">
          <div
            className="w-full h-48 lg:h-[261px] bg-gray-200"
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: selectedProperty
                ? `linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url('${selectedProperty.headPicUrl}')`
                : '',
            }}
          />
          <div
            className="w-[calc(100%-40px)] m-auto mt-[-40px] py-4 rounded-t-xl flex flex-col items-center"
            style={{ background: 'linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%)' }}
          >
            {/* Subheading for co-living */}
            {isCoLivingProperty && (
              <Typography className="!font-Averta4 !text-xs text-center flex items-center text-[#86909C]">
                Discover our co-living space
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <BootstrapTooltip
                      onClose={handleTooltipClose}
                      open={tooltipOpen}
                      title={
                        <Typography className="font-Averta4 !text-xs">
                          Co-living spaces feature shared living rooms, kitchens, and additional communal areas.
                        </Typography>
                      }
                    >
                      <IconButton onClick={handleTooltipOpen}>
                        <QuestionCircle />
                      </IconButton>
                    </BootstrapTooltip>
                  </div>
                </ClickAwayListener>
              </Typography>
            )}
            <ScheduleTourHeader
              fixedDom={fixedDom}
              theme="black"
              pageType={pageType}
              list={propertyList}
              showMenu={propertyList.length > 1}
              onChange={onPropertyChange}
              selected={selectedProperty?.id ?? ''}
            />
            {selectedProperty?.address && (
              <Typography className="!font-Averta4 !text-sm !text-center">{selectedProperty.address}</Typography>
            )}
          </div>
          <Box className="py-[13px] px-5 lg:py-6 lg:px-10">
            <div className="w-full flex items-center justify-between sticky lg:relative top-0 bg-white z-10 lg:z-1 mb-2">
              <Typography className="!font-Averta6 !text-base">Select your desired homes</Typography>
              <div className="flex items-center font-averta ">
                {!isMobile && (
                  <span className="mr-2.5 text-sm font-[400]">
                    {selectedCount}/{MAX_SELECTED_UNIT}
                  </span>
                )}

                <button onClick={onClear} type="button" className="underline text-sm">
                  Clear
                </button>
              </div>
            </div>
            {isVarsityProperty ? null : (
              <Box className="w-full overflow-x-auto pb-1.5">
                <Stack direction="row" spacing={1}>
                  {filterOptions.map(filter => (
                    <Button
                      key={filter.key}
                      className="!font-Averta6 !text-xs"
                      onClick={onSearchUnits}
                      data-bd={filter.value}
                      sx={{
                        border: `1px solid ${filter.value === bedroomValue ? '#050A22' : '#E0E2EF'}`,
                        color: '#050A22',
                        borderRadius: '8px',
                        padding: '8px 16px',
                        minWidth: '100px',
                        textTransform: 'none',
                      }}
                    >
                      {filter.label}
                    </Button>
                  ))}
                </Stack>
              </Box>
            )}
          </Box>
          <div className="w-full pb-[95px]">
            {isVarsityProperty ? (
              <ScheduleTourFloorPlan
                questionnaireId={questionnaireId}
                selectedFloorPlans={selectedFloorPlans}
                propertyId={selectedProperty?.id ?? ''}
                onchange={onSelectFloorPlanChange}
                floorPlans={floorPlanList}
              />
            ) : (
              <ScheduleTourUnit
                isCoLivingProperty={isCoLivingProperty}
                questionnaireId={questionnaireId}
                selectedUnits={selectedUnits}
                propertyId={selectedProperty?.id ?? ''}
                onchange={onSelectUnitChange}
                units={unitList}
              />
            )}
          </div>
        </div>
      )}

      {pageType === 'submitTour' && !isVarsityProperty && (
        <SubmitTourPage
          isMobile={isMobile}
          fixedDom={fixedDom}
          pageType={pageType}
          propertyList={propertyList}
          isReschedule={isReschedule}
          onFooterBack={onFooterBack}
          selectedProperty={selectedProperty}
          isCentralizedStage={isCentralizedStage}
          prospectId={prospectId}
          tourTypeList={tourTypeList}
          tourType={tourType}
          selfGuidTourUnits={selfGuidTourUnits}
          agentInfo={agentInfo}
          selectedUnits={selectedUnits}
          selectedPropertyTimeZone={selectedPropertyTimeZone}
          setTourType={setTourType}
          onBackNewUserId={onBackNewUserId}
          setErrorMessageOfAddingTour={setErrorMessageOfAddingTour}
          setSelectedAgentTime={setSelectedAgentTime}
          setIsNoAvailableDay={setIsNoAvailableDay}
          errorMessageOfAddingTour={errorMessageOfAddingTour}
          setPageType={setPageType}
        />
      )}

      {pageType === 'submitTour' && isVarsityProperty && (
        <SubmitVarsityTourPage
          isMobile={isMobile}
          fixedDom={fixedDom}
          pageType={pageType}
          propertyList={propertyList}
          isReschedule={isReschedule}
          onFooterBack={onFooterBack}
          selectedProperty={selectedProperty}
          isCentralizedStage={isCentralizedStage}
          prospectId={prospectId}
          tourTypeList={tourTypeList}
          tourType={tourType}
          selfGuidTourUnits={selfGuidTourUnits}
          agentInfo={agentInfo}
          selectedFloorPlans={selectedFloorPlans}
          selectedPropertyTimeZone={selectedPropertyTimeZone}
          setTourType={setTourType}
          onBackNewUserId={onBackNewUserId}
          setErrorMessageOfAddingTour={setErrorMessageOfAddingTour}
          setSelectedAgentTime={setSelectedAgentTime}
          setIsNoAvailableDay={setIsNoAvailableDay}
          errorMessageOfAddingTour={errorMessageOfAddingTour}
          setPageType={setPageType}
          onPropertyChange={onPropertyChange}
        />
      )}
      {pageType !== 'tourInfo' ? getFooter() : null}
      {pageType === 'tourInfo' && (
        <>
          {fixedDom ? <div className="lg:w-full w-1/2 h-6 bg-white lg:block hidden" /> : null}
          <div className="fixed lg:relative  top-0 w-full z-10 bg-white">
            <ScheduleTourHeader
              fixedDom={fixedDom}
              pageType={pageType}
              list={propertyList}
              selected={selectedProperty?.id ?? ''}
              theme="black"
              showBack={false}
              showMenu={headerList.length > 1}
              onChange={onPropertyChange}
            />
          </div>
          <div className="pt-[52px] pb-[95px]">
            <ScheduleTourDetail
              setSelectedFloorPlans={setSelectedFloorPlans}
              setSearchParams={setSearchParams}
              onClickCard={onPropertyChange}
              tourList={tourList}
              propertyId={selectedProperty?.id ?? ''}
              propertyList={propertyList}
              selectedUnits={selectedUnits}
              prospectUserName={prospectUserName}
              property={{
                name: selectedProperty ? selectedProperty.aliasName || selectedProperty.name : 'Unknown',
                address: selectedProperty?.address ?? 'unknown',
              }}
              onAnotherCallback={onAnotherCallback}
              selectedProperty={selectedProperty}
              preference={selectedPreferences}
              setPageType={setPageType}
              setRescheduleStatus={setRescheduleStatus}
              setCurrTourId={setCurrTourId}
              prospectId={prospectId}
              setScheduledUnitIds={setScheduledUnitIds}
            />
          </div>
        </>
      )}
      <Dialog open={checkUnitTimeDialog} onClose={() => afterCloseCheckUnitTimeDialog(false)}>
        <Box className="!p-5 lg:max-w-[335px]">
          <Typography className="!text-[#050A22] !text-base !font-Averta4 !mb-12">
            Some of the units you&apos;re interested in won&apos;t be available during the selected tour time. Would you like to continue
            scheduling?
          </Typography>
          <Box className="flex justify-between">
            <Box
              onClick={() => afterCloseCheckUnitTimeDialog(true)}
              className="!text-[#050A22] !px-[17px] !py-2 !border !border-[#050A22] !rounded-xl !font-Averta6 !text-base !capitalize"
            >
              Schedule
            </Box>
            <Box
              onClick={() => afterCloseCheckUnitTimeDialog(false)}
              className="!text-white !bg-[#E74F4F] !px-[17px] !py-2 !rounded-xl !font-Averta6 !text-base !capitalize"
            >
              Update
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  )
  return (
    <div className="w-full bg-[#F7F9FC] min-h-screen">
      {!isMobile && (
        <>
          <div className="bg-[#111212] sticky z-20 top-0  px-10 h-16 flex items-center justify-start">
            <img src={Number(version) ? RentyLogo : Logo} className="h-6" alt="tripalink" />
            <div className="bg-[white] mx-8 h-5 w-[1px] border-box" />
            <Typography className="!font-Averta6 !text-lg !text-[white]">Schedule Tour</Typography>
          </div>
          {openErrorMsg && (
            <div className="fixed m-auto z-40 -translate-x-1/2 left-[50%] top-[74px] text-[white] text-sm font-Averta4 rounded bg-[rgba(13,19,51,0.8)] px-4 py-2.5 flex items-center">
              <img src={warning} alt="" className="mr-1" />
              <span>You can only select up to 4 for your tour</span>
            </div>
          )}
        </>
      )}
      {isNoAvailableDay === true || (isTourDisabled?.value && pageType !== 'unit') ? <EndStep /> : body}
    </div>
  )
}

export default ScheduleTour
