import React, { FC, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Dialog, Divider, Typography } from '@mui/material'
import moment from 'moment'
import momentTimezone from 'moment-timezone'

import TourCompleteImg from '../../assets/imgs/tourComplete.png'
import { TOUR_CANCEL } from '../../graphqls/mutations'
import { QUERY_TOUR_LIST_BY_PROSPECT_ID } from '../../graphqls/queries'
import AddressIcon from '../../icons/scheduleTour/address'
import DateIcon from '../../icons/scheduleTour/date'
import HouseIcon from '../../icons/scheduleTour/houseIcon'
import TourTypeIcon from '../../icons/scheduleTour/tourType'
import { checkIsMobile } from '../../utils/agent'
import { getCurrency } from '../../utils/index'
import CustomAddToCalendarButton from '../scheduleTour/customAddToCalendarButton'
import {
  getTourTypeName,
  PageTypeType,
  RentalTypeEnum,
  AvailableFloorPlan,
  ScheduleTourPropertyType,
  ScheduleTourTourType,
  SelectedUnitsType,
  TourCancelReasonList,
  TourStatusList,
} from './contantsAndType'
import SelectedForm from './selectedForm'

const isMobile = checkIsMobile()

const IMG_URL = process.env.REACT_APP_IMG_URL
const TourDetailImg = `${IMG_URL}0674ca3028875846.png`
const TourCancelImg = `${IMG_URL}59c95d51933c34c0.png`
const DialogCloseIcon = `${IMG_URL}0f8060e81ecba45d.png`

const TourHeaderImg = {
  SCHEDULED: TourDetailImg,
  CANCELED: TourCancelImg,
  FINISHED: TourCompleteImg,
}

interface ScheduleTourDetailProps {
  tourList: ScheduleTourTourType[]
  propertyId: string
  propertyList: ScheduleTourPropertyType[]
  property: { name: string; address: string }
  selectedUnits: SelectedUnitsType
  selectedProperty: ScheduleTourPropertyType | undefined
  // eslint-disable-next-line react/require-default-props
  preference?: string[]
  onClickCard: (propertyId: string) => void
  setPageType: (value: PageTypeType) => void
  setRescheduleStatus: (value: boolean) => void
  setCurrTourId: (value: number) => void
  prospectId: string
  setScheduledUnitIds: (value: string[]) => void
  onAnotherCallback?: () => void
  setSearchParams: (value: URLSearchParams) => void
  prospectUserName: string
  setSelectedFloorPlans: (value: AvailableFloorPlan[]) => void
}

const ScheduleTourDetail: FC<ScheduleTourDetailProps> = props => {
  const {
    tourList,
    propertyId,
    propertyList,
    onClickCard,
    property,
    selectedProperty,
    setSelectedFloorPlans,
    selectedUnits,
    preference,
    setPageType,
    setRescheduleStatus,
    prospectId,
    setScheduledUnitIds,
    setCurrTourId,
    onAnotherCallback,
    setSearchParams,
    prospectUserName,
  } = props

  const [activeCancelReason, setCancelReason] = useState('')
  const [cancelReasonNote, setCancelReasonNote] = useState('')
  const [isShowCancelReason, setShowCancelReason] = useState(false)
  const [isShowTourInfo, setShowTourInfo] = useState(false)
  const [currentTour, setCurrTour] = useState<ScheduleTourTourType>()

  const isOutdatedTour = useMemo(() => moment(currentTour?.startTime).isBefore(moment()), [currentTour?.startTime])

  const isVarsityProperty = useMemo(() => currentTour?.properties?.[0]?.rentalType === RentalTypeEnum.STUDENT_FLATSHARE, [currentTour])

  const [cancelTour, { loading: cancelTourLoading }] = useMutation(TOUR_CANCEL, {
    notifyOnNetworkStatusChange: true,
    onCompleted: res => {
      const { cancelTour = {} } = res || {}
      const { ret } = cancelTour || {}

      if (ret) {
        setShowCancelReason(false)
        queryTourListByProspectIdApi()
      }
    },
  })

  const [queryTourListByProspectIdApi] = useLazyQuery(QUERY_TOUR_LIST_BY_PROSPECT_ID, {
    fetchPolicy: 'network-only',
    variables: {
      prospectId,
    },
    onCompleted(res) {
      const { queryTourListByProspectId } = res || {}
      const { data } = queryTourListByProspectId || {}
      const curTourList = Array.isArray(data) ? data : []
      const { id } = currentTour || {}
      setCurrTour(curTourList.find(item => propertyId && String(item.propertyId) === String(propertyId) && id === item.id))
    },
  })

  const { tourAgentId, eventId, units } = currentTour || {}

  useEffect(() => {
    const tourSchedule = tourList.find(item => propertyId && String(item.propertyId) === String(propertyId))
    setCurrTour(tourSchedule)
  }, [])

  const onCancelTour = () => {
    cancelTour({
      variables: {
        input: {
          tourId: Number(currentTour?.id),
          eventId,
          cancelReason: activeCancelReason,
          cancelNote: activeCancelReason === TourCancelReasonList[3] ? cancelReasonNote : '',
          userId: tourAgentId,
          cancelSource: 'USER',
        },
      },
    })
  }

  const onReScheduleTour = () => {
    setPageType('unit')
    setRescheduleStatus(false)
    setCurrTourId(0)
    const newSearchParams = new URLSearchParams()
    newSearchParams.set('bedroomNum', '')
    setSearchParams(newSearchParams)
  }

  const onReScheduleTourSubmit = () => {
    setPageType('submitTour')
    setRescheduleStatus(true)
    setCurrTourId(Number(currentTour?.id || 0))
    const currTourUnitIds = units?.map(item => String(item.unitId))
    setScheduledUnitIds(currTourUnitIds && currTourUnitIds.length > 0 ? currTourUnitIds : [''])
    if (isVarsityProperty) {
      const floorPlans: any = currentTour?.properties?.map(item => ({ floorPlanId: item?.floorPlanId, floorPlanName: item?.floorPlanName }))
      setSelectedFloorPlans(floorPlans || [])
    }
  }
  const onBack = () => {
    setPageType('unit')
    onAnotherCallback?.()
  }

  const tourUnitStr = useMemo(() => {
    if (!currentTour) return ''
    if (isVarsityProperty) {
      return currentTour?.properties?.map(item => item?.floorPlanName).join(';')
    }
    return currentTour?.units?.map(item => item?.unitName).join(';')
  }, [currentTour])

  const noTourPropertyList = useMemo(
    () => propertyList.filter(property => tourList.every(tour => String(tour.propertyId) !== String(property.id))),
    [tourList, propertyList],
  )
  const haveTourPropertyList = useMemo(() => {
    const allHaveTourPropertyList = propertyList.filter(property => tourList.some(tour => String(tour.propertyId) === String(property.id)))
    const result = allHaveTourPropertyList.filter(property => !!currentTour && String(property.id) !== String(currentTour.propertyId))
    return result
  }, [currentTour, tourList, propertyList])
  if (!currentTour) return null

  return (
    <div className="pm-5 schedule-tour-detail">
      <div className="px-5 py-4 flex flex-col items-center">
        <img className="w-20 h-20" src={TourHeaderImg[currentTour.status]} alt="tour detial" />
        <Typography className="!mt-4 !font-Averta6 !text-lg !text-center">
          Your {getTourTypeName(currentTour.tourType)?.toLocaleLowerCase()} is {TourStatusList[currentTour.status]}
        </Typography>
      </div>
      {currentTour.status !== 'CANCELED' ? (
        <>
          <div className="w-[calc(100%-40px)] m-auto my-4 rounded-2xl bg-[#F2F6FD]">
            <div className="px-5 py-3 flex items-start">
              <Typography className="!text-[#75798D] !font-Averta4 !text-base">Tour Host</Typography>
              <Divider orientation="vertical" variant="middle" sx={{ borderColor: '#E0E2EF', margin: '0 8px' }} flexItem />
              <Typography className="!text-[#050A22] !font-Averta6 !text-base">{currentTour.tourAgentName}</Typography>
            </div>
            <div className="bg-white rounded-2xl px-5 py-5" style={{ boxShadow: '0px 2px 5px 0px #0000000A' }}>
              <div className="grid grid-cols-1 gap-y-4">
                <div className="flex items-start justify-start leading-6">
                  <DateIcon className="!text-base mt-0.5" />
                  &nbsp;
                  <div>
                    <Typography className="!font-Averta6 !text-base !text-[#050A22]">
                      {currentTour.startTime
                        ? `${momentTimezone(currentTour.startTime).tz(currentTour.timeZone).format('h:mm A, dddd, MMM DD, YYYY')}`
                        : '-'}
                    </Typography>
                    <Typography className="!font-Averta4 text-themeColor !text-sm">
                      {currentTour.timeZoneText ? `${currentTour.timeZoneText}` : '-'}
                    </Typography>
                  </div>
                </div>

                <div className="flex items-start justify-start leading-6">
                  <HouseIcon className="!text-base mt-0.5" />
                  &nbsp;
                  {Array.isArray(currentTour.units) && currentTour.units.length ? (
                    <div className="flex flex-col items-start justify-center">
                      <Typography className="text-themeColor !text-base !font-Averta6">{currentTour?.propertyName}</Typography>
                      <Typography className="text-themeColor !text-sm !font-Averta4">{tourUnitStr}</Typography>
                    </div>
                  ) : (
                    <Typography className="text-themeColor !text-base !font-Averta6">{currentTour?.propertyName}</Typography>
                  )}
                </div>

                <div className="flex items-start justify-start leading-6">
                  <AddressIcon className="!text-base mt-0.5" />
                  &nbsp;
                  <Typography className="!font-Averta6 !text-base text-themeColor">{currentTour.propertyAddress || '-'}</Typography>
                </div>
                <div className="flex items-start justify-start leading-6">
                  <TourTypeIcon className="!text-base mt-0.5" />
                  &nbsp;
                  <Typography className="!font-Averta6 !text-base text-themeColor">
                    {currentTour?.tourType ? getTourTypeName(currentTour.tourType) : '-'}
                  </Typography>
                </div>
                {currentTour.preference && <Divider orientation="horizontal" />}

                {Array.isArray(currentTour.preference) && currentTour.preference.length > 0 && (
                  <div className="flex flex-col items-start justify-center">
                    <Typography className="!text-[#75798D] !text-sm !font-Averta4">Preference</Typography>
                    <Typography className="!text-[#050A22] !text-base !font-Averta6">{currentTour.preference.join('; ')}</Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full px-4 mb-1">
            <CustomAddToCalendarButton data={{ ...currentTour, prospectUserName }} />
          </div>
          {!isOutdatedTour && currentTour.status === 'SCHEDULED' && (
            <div className="flex px-5 text-center font-semibold font-Averta6">
              <div className="rounded-xl w-1/2 btn font-semibold !text-base py-3 cursor-pointer" onClick={() => setShowCancelReason(true)}>
                Cancel
              </div>
              <div className="rounded-xl w-1/2 ml-2 btn font-semibold !text-base py-3 cursor-pointer" onClick={onReScheduleTourSubmit}>
                Reschedule
              </div>
            </div>
          )}
          {(isOutdatedTour || currentTour.status === 'FINISHED') && (
            <div className="w-full px-4 mt-1">
              <button
                onClick={onBack}
                type="button"
                aria-label="reschedule"
                className="bg-[#E74F4F] text-base font-bold  w-full h-12 rounded-xl text-white"
              >
                Schedule another tour
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          {currentTour.startTime && (
            <div
              className={`flex items-center justify-between font-semibold cancel-tour-info ${
                isMobile ? 'w-[calc(100%-40px)]' : 'w-[335px]'
              } m-auto h-14 px-4 leading-14 text-base rounded-lg bg-[#F7F9FC] mb-14`}
            >
              <span>{`${momentTimezone(currentTour.startTime).tz(currentTour.timeZone).format('h:mm a, dddd, MMM DD, YYYY')}`}</span>
              <span className="underline decoration-1 cursor-pointer" onClick={() => setShowTourInfo(true)}>
                View
              </span>
            </div>
          )}
          <div
            className={`${
              isMobile ? 'w-[calc(100%-40px)]' : 'w-[335px]'
            } m-auto flex justify-center items-center font-semibold text-center rounded-xl !text-base h-12 cursor-pointer bg-red-450 text-[#fff]`}
            onClick={onReScheduleTour}
          >
            Schedule tour
          </div>
        </>
      )}

      {noTourPropertyList.length > 0 && (
        <div className="w-[calc(100%-40px)] m-auto mt-8 mb-4">
          <Typography className="!font-Averta6 !text-[#181414] !text-lg !mb-4">Other properties you may want to tour</Typography>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-2.5 gap-y-5">
            {noTourPropertyList.map(item => (
              <PropertyCard property={item} key={item.id} onClick={() => onClickCard(item.id)} />
            ))}
          </div>
        </div>
      )}
      {haveTourPropertyList.length > 0 && (
        <div className="w-[calc(100%-40px)] m-auto mt-8 mb-4">
          <Typography className="!font-Averta6 !text-[#181414] !text-lg !mb-4">Property has scheduled for tour</Typography>
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-2.5 gap-y-5">
            {haveTourPropertyList.map(item => (
              <PropertyCard property={item} key={item.id} onClick={() => onClickCard(item.id)} />
            ))}
          </div>
        </div>
      )}
      {/* Reason list PC or mobile */}
      <Dialog
        open={isShowCancelReason}
        className={`${isMobile ? 'mobile-box' : ''} reason-list-dialog-box`}
        onClose={() => setShowCancelReason(false)}
      >
        {isMobile && (
          <div className="text-center mt-2 mb-2 w-[22px] mx-auto">
            <div className="w-[22px] h-0.5 rounded-full bg-[#C7CBD1]" />
          </div>
        )}

        <p className="text-[#050A22] text-base mt-5 font-semibold mb-6">Reason for cancellation</p>
        <div>
          {TourCancelReasonList.map(item => (
            <div
              key={item}
              onClick={() => setCancelReason(item)}
              className={`${
                activeCancelReason === item ? '!border-[1px] !border-[#050A22] !bg-[#F7F9FC]' : ''
              } text-sm h-12 leading-12 font-normal rounded-[100px] border border-slate-200 pl-6 mb-3 cursor-pointer flex items-center`}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="other-reason-note h-[100px]">
          {activeCancelReason === TourCancelReasonList[3] && (
            <textarea
              className="reason-text-area w-full border-[1px] border-gray-550 rounded-xl resize-none text-xs px-5 py-[9px]"
              placeholder="Please specify the reason (optional)"
              onChange={e => setCancelReasonNote(e.currentTarget.value)}
            />
          )}
        </div>
        <div className="flex text-center font-semibold mb-4">
          <div
            className="flex items-center  justify-center rounded-xl w-1/2 btn !text-base h-12 leading-12 cursor-pointer"
            onClick={() => setShowCancelReason(false)}
          >
            Back
          </div>
          <LoadingButton
            onClick={() => {
              if (!activeCancelReason) return
              onCancelTour()
            }}
            loading={cancelTourLoading}
            className={`${
              !activeCancelReason || cancelTourLoading ? '!bg-[#F4F5FA] !text-[#A6A9BB]' : '!bg-[#E74F4F] !text-white'
            } w-[calc(50%-8px)] !rounded-xl !py-3 text-center !ml-2`}
          >
            <Typography className="!text-base !font-Averta6 font-semibold  capitalize">Cancel Tour</Typography>
          </LoadingButton>
        </div>
      </Dialog>

      <Dialog
        open={isShowTourInfo}
        onClose={() => setShowTourInfo(false)}
        className={`${isMobile ? 'mobile-box' : ''} reason-list-dialog-box`}
      >
        {isMobile && (
          <div className="text-center mt-2 mb-2 w-[22px] mx-auto">
            <div className="w-[22px] h-0.5 rounded-full bg-[#C7CBD1]" />
          </div>
        )}

        <div className="mb-6 mt-5 flex justify-between">
          <p className="text-[#050A22] text-base font-semibold">Canceled Tour</p>

          <div onClick={() => setShowTourInfo(false)}>
            <img src={DialogCloseIcon} className="w-3 h-3 relative top-1.5 cursor-pointer" alt="close icon" />
          </div>
        </div>

        <div className="max-h-[440px] mb-3 overflow-y-auto">
          <SelectedForm
            selectedUnits={selectedUnits}
            property={property}
            preference={preference}
            pageType="tourInfo"
            tourOption={getTourTypeName(currentTour.tourType)}
            tourTime={`${momentTimezone(currentTour.startTime).tz(currentTour.timeZone).format('h:mm a, dddd, MMM DD, YYYY')} ${
              currentTour.timeZoneText
            }`}
            tourHost={currentTour.tourAgentName}
            cancelReason={currentTour.cancelReason}
            cancelNote={currentTour.cancelNote}
          />
        </div>

        {isMobile && (
          <div
            className="my-4 rounded-xl btn !text-base h-12  flex items-center justify-center cursor-pointer text-center font-semibold"
            onClick={() => setShowTourInfo(false)}
          >
            Close
          </div>
        )}
      </Dialog>
    </div>
  )
}

const PropertyCard: FC<{ property: ScheduleTourPropertyType; onClick: () => void }> = props => {
  const { property, onClick } = props
  return (
    <Box style={{ boxShadow: '0px 0px 3px 0px #242A2E1A' }} className="rounded-xl overflow-hidden" onClick={onClick}>
      <img src={property.headPicUrl} alt="" className="w-full h-[194px]" />
      <div className="p-3 flex items-start flex-col ">
        {property.minPrice && (
          <div className="mb-2 flex flex-row items-baseline justify-start">
            <Typography className="!font-Averta6 !font-bold !text-[#050A22] !text-[22px] ">{`$${getCurrency(
              property.minPrice,
            )}+/mo`}</Typography>
            &nbsp;&nbsp;
            {property.minOriginalPrice && Number(property.minOriginalPrice) > Number(property.minPrice) && (
              <Typography className="!font-Averta4 line-through  !text-[#75798D] !text-sm ">
                {`$${getCurrency(property.minOriginalPrice)}`}
              </Typography>
            )}
          </div>
        )}
        <Typography className="!font-Averta6 !text-[#050A22] !text-base !mb-2">{property.aliasName || property.name}</Typography>
        {property?.amenities?.length > 0 && (
          <Box className="!font-Averta6 !text-[#050A22] !text-base flex items-center flex-wrap h-10 overflow-hidden">
            {property?.amenities?.map(amenitie => (
              <span
                key={amenitie.catalogName}
                className="bg-[#F7F9FC] mr-2 mb-2 w-[fit-content] rounded-lg px-2 py-1 text-[#75798D] font-Averta4"
              >
                {amenitie.catalogName}
              </span>
            ))}
          </Box>
        )}
      </div>
    </Box>
  )
}

export default ScheduleTourDetail
