import React, { useMemo, useState } from 'react'
import { Checkbox, Stack, Typography } from '@mui/material'
import { compact, uniqBy } from 'lodash'
import { Statsig } from 'statsig-react'
import NoFloorPlan from '../../assets/imgs/noFloorPlan.png'
import CheckImage from './checkImage'
import { AvailableFloorPlan, SelectedFloorPlanType } from './contantsAndType'

interface ScheduleTourFloorPlanProps {
  questionnaireId: string
  propertyId: string
  floorPlans?: AvailableFloorPlan[]
  selectedFloorPlans: AvailableFloorPlan[]
  onchange: (selectedFloorPlans: AvailableFloorPlan[]) => void
}
const ScheduleTourFloorPlan = (props: ScheduleTourFloorPlanProps) => {
  const { floorPlans, selectedFloorPlans, propertyId, onchange, questionnaireId } = props
  const isShowPic = useMemo(() => floorPlans?.some(item => item?.floorPlanPicUrl), [floorPlans])

  const onSelectValueChange = (value: AvailableFloorPlan) => {
    const isAlreadySaved = selectedFloorPlans.some(plan => plan.floorPlanId === value.floorPlanId)

    let updatedFloorPlans
    if (isAlreadySaved) {
      updatedFloorPlans = selectedFloorPlans.filter(plan => plan.floorPlanId !== value.floorPlanId)
    } else {
      updatedFloorPlans = compact([...selectedFloorPlans, value])
    }

    // Trigger onchange with the updated floor plans ensuring uniqueness
    onchange(uniqBy(updatedFloorPlans, 'floorPlanId'))
  }
  return (
    <div className="px-5 py-2 lg:py-4  lg:pt-0 lg:px-10 grid grid-cols-1 gap-y-2">
      {floorPlans?.map(floorPlan => (
        <ScheduleTourFloorPlanItem
          selectedFloorPlans={selectedFloorPlans}
          isShowPic={isShowPic}
          propertyId={propertyId}
          questionnaireId={questionnaireId}
          key={floorPlan.floorPlanId}
          floorPlan={floorPlan}
          onChange={(value: AvailableFloorPlan) => onSelectValueChange(value)}
        />
      ))}
    </div>
  )
}

interface ScheduleTourFloorPlanItemProps {
  questionnaireId: string
  floorPlan: AvailableFloorPlan
  propertyId: string
  isShowPic?: boolean
  selectedFloorPlans: SelectedFloorPlanType[]
  onChange: (value: AvailableFloorPlan) => void
}
const ScheduleTourFloorPlanItem = (props: ScheduleTourFloorPlanItemProps) => {
  const { floorPlan, onChange, propertyId, questionnaireId, isShowPic, selectedFloorPlans } = props
  const [checkImage, setCheckImage] = useState(false)

  const floorPlanTitle = floorPlan?.floorPlanName

  const floorPlanArea = useMemo(() => {
    const minArea = Number(floorPlan.minSqrFt)
    const maxArea = Number(floorPlan.maxSqrFt)
    return minArea === maxArea ? `${minArea} sqft` : `${minArea} - ${maxArea} sqft`
  }, [floorPlan])

  const selectedFloorPlanIds = useMemo(() => selectedFloorPlans?.map(item => item?.floorPlanId), [selectedFloorPlans])
  const selectFloorPlan = useMemo(() => selectedFloorPlanIds?.includes(floorPlan?.floorPlanId), [selectedFloorPlanIds])
  const floorPlanPrice = useMemo(() => {
    const { maxRentPrice, minRentPrice } = floorPlan

    return {
      minPrice: Number((minRentPrice.discountAmount || 0) / 100),
      maxPrice: Number((maxRentPrice.discountAmount || 0) / 100),
    }
  }, [floorPlan])
  const onCheckImage = () => {
    Statsig.logEvent('schedule_tour_click_unit_image', window.location.href, {
      questionnaireId,
      unitName: floorPlan.name,
      floorUrl: floorPlan.floorPlanPicUrl,
      propertyId,
    })
    setCheckImage(true)
  }

  const changeFloorPlanSelect = () => {
    onChange(floorPlan)
  }

  return (
    <div className={`p-4 border rounded-lg ${selectFloorPlan ? 'border-[#050A22]' : 'border-[#E0E2EF]'}`}>
      <CheckImage
        src={floorPlan.floorPlanPicUrl}
        alt=""
        onClose={() => {
          setCheckImage(false)
        }}
        visible={checkImage}
      />
      <div className="flex">
        {isShowPic ? (
          <img
            alt=""
            src={floorPlan.floorPlanPicUrl ? floorPlan.floorPlanPicUrl : NoFloorPlan}
            aria-hidden="true"
            onClick={onCheckImage}
            className="w-[88px] h-[88px]"
          />
        ) : null}
        <div aria-hidden="true" onClick={changeFloorPlanSelect} className="ml-4 flex-grow flex justify-between flex-col">
          <div className="w-full relative mb-[5px]">
            <div className="mr-[26px]">
              <Typography className="!font-Averta6 !font-semibold !text-base !text-[#050A22] break-all">{floorPlanTitle}</Typography>
              <Stack direction="row" spacing={1}>
                <Typography className="table px-2 py-0.5 rounded-[18px] !text-[10px] !text-[#050A22] !font-Averta4 bg-slate-50">
                  {floorPlanArea}
                </Typography>
              </Stack>
            </div>
            <Checkbox
              className="!absolute !top-0 !right-0"
              sx={{
                color: '#E0E2EF',
                padding: '0',
                '&.Mui-checked': {
                  color: '#050A22',
                },
                '& svg': {
                  fontSize: '18px',
                },
              }}
              checked={selectedFloorPlanIds?.includes(floorPlan?.floorPlanId)}
            />
          </div>
          <div className="flex justify-between items-center">
            <Typography className="!font-Averta6 !text-[#050A22] !text-base">{`$${floorPlanPrice.minPrice.toLocaleString()} - $${floorPlanPrice.maxPrice.toLocaleString()}`}</Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScheduleTourFloorPlan
