import React, { useMemo, useState } from 'react'
import { Checkbox, Stack, Typography } from '@mui/material'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { Statsig } from 'statsig-react'
import NoFloorPlan from '../../assets/imgs/noFloorPlan.png'
import CheckImage from './checkImage'
import { AvailableUnit, RoomType, SelectedUnitInfoProps, SelectedUnitsType } from './contantsAndType'

interface ScheduleTourFloorPlanProps {
  questionnaireId: string
  propertyId: string
  units: AvailableUnit[]
  selectedUnits: SelectedUnitsType
  isCoLivingProperty: boolean
  onchange: (selectedUnits: SelectedUnitsType) => void
}
const ScheduleTourUnit = (props: ScheduleTourFloorPlanProps) => {
  const { units, selectedUnits, propertyId, onchange, questionnaireId, isCoLivingProperty } = props
  const isShowPic = useMemo(() => units?.some(item => item?.floorPlanPicUrl), [units])

  const onSelectValueChange = (value: { unitsInfo: SelectedUnitInfoProps } | undefined, key: string) => {
    const newSelectedUnits = new Map(selectedUnits)
    if (value) {
      newSelectedUnits.set(key, value)
    } else {
      newSelectedUnits.delete(key)
    }
    onchange(newSelectedUnits)
  }

  return (
    <div className="px-5 py-2 lg:py-4  lg:pt-0 lg:px-10 grid grid-cols-1 gap-y-5">
      {units.map(unit => (
        <ScheduleTourFloorPlanItem
          isShowPic={isShowPic}
          isCoLivingProperty={isCoLivingProperty}
          propertyId={propertyId}
          questionnaireId={questionnaireId}
          key={unit.id}
          unit={unit}
          value={selectedUnits.get(`${unit.name}`)}
          onChange={(value: { unitsInfo: SelectedUnitInfoProps } | undefined) => {
            onSelectValueChange(value, unit.name)
          }}
        />
      ))}
    </div>
  )
}

interface ScheduleTourFloorPlanItemProps {
  questionnaireId: string
  unit: AvailableUnit
  propertyId: string
  value: { unitsInfo: SelectedUnitInfoProps } | undefined
  isCoLivingProperty: boolean
  isShowPic: boolean
  onChange: (value: { unitsInfo: SelectedUnitInfoProps } | undefined) => void
}
const ScheduleTourFloorPlanItem = (props: ScheduleTourFloorPlanItemProps) => {
  const { unit, value, onChange, propertyId, questionnaireId, isCoLivingProperty, isShowPic } = props
  const [checkImage, setCheckImage] = useState(false)
  const floorPlanPrice = useMemo(() => {
    const { maxRentPrice, minRentPrice } = unit

    return {
      minPrice: Number((minRentPrice.discountAmount || 0) / 100),
      maxPrice: Number((maxRentPrice.discountAmount || 0) / 100),
    }
  }, [unit])
  const floorPlanTitle = useMemo(() => {
    const { floorPlanBedroomNum, name } = unit

    const planName = name ? ` - ${name}` : ''
    return Number(floorPlanBedroomNum) === 0 ? `Studio${planName}` : name
  }, [unit])

  const floorPlanArea = useMemo(() => {
    const minArea = Number(unit.minSqrFt)
    const maxArea = Number(unit.maxSqrFt)
    return minArea === maxArea ? `${minArea} sqft` : `${minArea} - ${maxArea} sqft`
  }, [unit])

  const selectUnit = useMemo(() => value && value.unitsInfo.unitName === unit.name, [value?.unitsInfo.unitName])
  const onCheckImage = () => {
    Statsig.logEvent('schedule_tour_click_unit_image', window.location.href, {
      questionnaireId,
      unitName: unit.name,
      floorUrl: unit.floorPlanPicUrl,
      propertyId,
    })
    setCheckImage(true)
  }

  const changeUnitSelect = () => {
    if (value) {
      onChange(undefined)
    } else {
      onChange({
        unitsInfo: { floorPlanIndex: unit.floorPlanIndex, unitName: unit.name, rooms: [], unitTitle: unit.name, unitId: Number(unit.id) },
      })
    }
  }
  const changeUnitItemSelect = (room: RoomType) => {
    const { id, name, availableStatus, preTourEndDate, preTourStartDate, availableStartTime } = room
    if (value) {
      const newValue = cloneDeep(value)
      const { rooms } = newValue.unitsInfo
      const target = rooms.findIndex((u: { id: any }) => String(u.id) === String(id))
      if (target > -1) {
        rooms.splice(target, 1)
      } else {
        rooms.push({ name, id: Number(id), availableStatus, availableStartTime, preTourStartDate, preTourEndDate, unitId: Number(unit.id) })
      }
      onChange(newValue)
    } else {
      onChange({
        unitsInfo: {
          unitTitle: unit.name,
          floorPlanIndex: unit.floorPlanIndex,
          unitName: unit.name,
          unitId: Number(unit.id),
          rooms: [{ name, id: Number(id), availableStatus, availableStartTime, preTourStartDate, preTourEndDate, unitId: Number(unit.id) }],
        },
      })
    }
  }
  return (
    <div className={`p-4 border rounded-lg ${selectUnit ? 'border-[#050A22]' : 'border-[#E0E2EF]'}`}>
      <CheckImage
        src={unit.floorPlanPicUrl}
        alt=""
        onClose={() => {
          setCheckImage(false)
        }}
        visible={checkImage}
      />
      <div className="flex">
        {isShowPic ? (
          <img
            alt=""
            src={unit.floorPlanPicUrl ? unit.floorPlanPicUrl : NoFloorPlan}
            aria-hidden="true"
            onClick={onCheckImage}
            className="w-[88px] h-[88px]"
          />
        ) : null}
        <div aria-hidden="true" onClick={changeUnitSelect} className="ml-4 flex-grow flex justify-between flex-col">
          <div className="w-full relative mb-[5px]">
            <div className="mr-[26px]">
              <Typography className="!font-Averta6 !font-semibold !text-base !text-[#050A22] break-all">{floorPlanTitle}</Typography>
              <Stack direction="row" spacing={1}>
                <Typography className="table px-2 py-0.5 rounded-[18px] !text-[10px] !text-[#050A22] !font-Averta4 bg-slate-50">
                  {`${unit.floorPlanBedroomNum} beds, ${unit.floorPlanBathroomNum} baths`}
                </Typography>
                <Typography className="table px-2 py-0.5 rounded-[18px] !text-[10px] !text-[#050A22] !font-Averta4 bg-slate-50">
                  {floorPlanArea}
                </Typography>
              </Stack>
            </div>
            <Checkbox
              className="!absolute !top-0 !right-0"
              sx={{
                color: '#E0E2EF',
                padding: '0',
                '&.Mui-checked': {
                  color: '#050A22',
                },
                '& svg': {
                  fontSize: '18px',
                },
              }}
              checked={!!value && value.unitsInfo.floorPlanIndex === unit.floorPlanIndex}
            />
          </div>
          <div className="flex justify-between items-center">
            <Typography className="!font-Averta6 !text-[#050A22] !text-base">{`$${floorPlanPrice.minPrice.toLocaleString()} - $${floorPlanPrice.maxPrice.toLocaleString()}`}</Typography>
          </div>
        </div>
      </div>
      {selectUnit && unit.rooms && (
        <div className="mt-5">
          <Typography className="!font-averta !text-sm !text-[#050A22]">
            Select your preferred {isCoLivingProperty ? 'rooms' : 'units'} (optional)
          </Typography>
          <div className="grid grid-cols-1 gap-y-3 mt-3">
            {Array.isArray(unit.rooms) &&
              unit.rooms.length > 0 &&
              unit.rooms.map((room: RoomType) => {
                const { id, name, rentPrice, tourAvailableStatus, tourAvailableStartTime, rentPriceLeaseTerm, privateBathroom } = room
                return (
                  <div key={id} aria-hidden="true" onClick={() => changeUnitItemSelect(room)} className="rounded-lg bg-[#F7F9FC] p-2">
                    <div className="flex justify-between items-center leading-[30px]">
                      <Typography className="!font-Averta6 !text-[#050A22] !text-base !font-semibold">{name}</Typography>
                      <div className="flex items-center">
                        <Typography className="!font-Averta6 !text-[#050A22] !text-base !font-semibold">
                          {rentPrice ? `$${Number(rentPrice.discountAmount / 100).toLocaleString()}` : ''}
                        </Typography>
                        {rentPrice && rentPrice.leaseTerm && (
                          <Typography className="!ml-1 !text-[10px] lg:!text-xs !font-Averta4 !text-[#75798D]">
                            {rentPriceLeaseTerm}
                          </Typography>
                        )}
                        &nbsp;&nbsp;
                        <Checkbox
                          sx={{
                            color: '#E0E2EF',
                            padding: '0',
                            background: '#fff',
                            '&.Mui-checked': {
                              color: '#050A22',
                            },
                            '& svg': {
                              fontSize: '18px',
                            },
                          }}
                          checked={!!value && value.unitsInfo.rooms.some((u: { id: any }) => String(u.id) === String(id))}
                        />
                      </div>
                    </div>
                    <Stack direction="row" justifyContent="space-between">
                      <div className="font-Averta4 text-[#050A22] text-xs">{privateBathroom ? 'Private bathroom' : 'Shared bathroom'}</div>
                      {tourAvailableStatus === 0 && <div className="font-Averta4 text-[#050A22] text-xs">Available now</div>}
                      {tourAvailableStatus === 1 && (
                        <div className="font-Averta4 text-[#050A22] text-xs">
                          Available from {moment(tourAvailableStartTime).format('MM/DD/YYYY')}
                        </div>
                      )}
                      {tourAvailableStatus === 2 && (
                        <div className="font-Averta4 text-[#050A22] text-xs">
                          Available from {moment(room.preTourStartDate).format('MM/DD/YYYY')} to
                          {moment(room.preTourEndDate).format('MM/DD/YYYY')}
                        </div>
                      )}
                    </Stack>
                  </div>
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

export default ScheduleTourUnit
