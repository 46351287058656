import React, { FC, useMemo } from 'react'
import { Typography } from '@mui/material'

import { AvailableFloorPlan, PageTypeType } from './contantsAndType'

interface FilledFormProps {
  isReschedule?: boolean
  setPageType?: (value: PageTypeType) => void
  selectedFloorPlans?: AvailableFloorPlan[]
}
const VarsitySelectedForm: FC<FilledFormProps> = props => {
  const { selectedFloorPlans, setPageType, isReschedule } = props
  const selectedFloorPlanStrs = useMemo(() => selectedFloorPlans?.map(item => item.floorPlanName), [selectedFloorPlans])
  const onClickFormItem = () => {
    if (isReschedule) {
      return
    }
    setPageType?.('unit')
  }
  return (
    <div className="py-3 px-6 w-full font-Averta">
      <Typography className="!font-Averta6 !text-base">Selected homes</Typography>
      <div onClick={onClickFormItem} className="flex  mt-2 justify-between bg-slate-50 w-full rounded-md">
        <div className="w-4/5 p-4 flex flex-wrap">
          {selectedFloorPlanStrs?.map(item => (
            <span className="font-bold text-sm text-default font-Averta4 w-full">{item}</span>
          ))}
        </div>
        {selectedFloorPlanStrs?.length && !isReschedule ? (
          <div className="w-1/3 pt-4 flex  justify-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 12.8538V15.5556C0 15.8045 0.195528 16 0.444383 16H3.14623C3.26177 16 3.37731 15.9556 3.4573 15.8667L13.1626 6.17025L9.82975 2.83738L0.133315 12.5338C0.0444384 12.6227 0 12.7293 0 12.8538ZM15.74 3.59283C16.0867 3.24622 16.0867 2.68629 15.74 2.33967L13.6603 0.259964C13.3137 -0.0866546 12.7538 -0.0866546 12.4072 0.259964L10.7807 1.8864L14.1136 5.21927L15.74 3.59283Z"
                fill="black"
              />
            </svg>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default VarsitySelectedForm
