import React from 'react'
import ScheduleTourHeader from './scheduleTourHeader'
import SelectedForm from './selectedForm'
import ScheduleTourWayAndTime from './scheduleTourWayAndTime'
import { getTourTypeName, PageTypeType, SelectedUnitsType, ScheduleTourPropertyType } from './contantsAndType'
import SubmitTourPc from './SubmitTourPc'
import ErrorSnackbar from '../errorSnackbar'

interface SubmitTourPageProps {
  isMobile: boolean
  fixedDom: boolean
  pageType: PageTypeType
  propertyList: ScheduleTourPropertyType[]
  isReschedule: boolean
  onFooterBack: () => void
  selectedProperty?: ScheduleTourPropertyType
  selectedUnits: SelectedUnitsType
  isCentralizedStage: boolean
  prospectId: string
  tourTypeList: { key: string; name: string; desc: string }[]
  tourType: string
  selfGuidTourUnits: { floorPlanName: string; modelUnitId: number; modelUnitName: string; units: { unitId: number; unitName: string }[] }[]
  agentInfo: { id: string; name: string }
  selectedPropertyTimeZone: { displayText: string; timeZone: string }
  setTourType: (val: string) => void
  onBackNewUserId: (val: { newAdminUserId: string; newAdminUserName: string }) => void
  setErrorMessageOfAddingTour: (val: { open: boolean; message: string }) => void
  setSelectedAgentTime: (val?: { time: string; startSecond: string }) => void
  setIsNoAvailableDay: (val: boolean) => void
  errorMessageOfAddingTour: { open: boolean; message: string }
  setPageType: (val: PageTypeType) => void
}

const SubmitTourPage = ({
  isMobile,
  fixedDom,
  pageType,
  propertyList,
  isReschedule,
  onFooterBack,
  selectedProperty,
  isCentralizedStage,
  prospectId,
  tourTypeList,
  tourType,
  selfGuidTourUnits,
  agentInfo,
  selectedUnits,
  selectedPropertyTimeZone,
  setTourType,
  onBackNewUserId,
  setErrorMessageOfAddingTour,
  setSelectedAgentTime,
  setIsNoAvailableDay,
  errorMessageOfAddingTour,
  setPageType,
}: SubmitTourPageProps) => {
  return (
    <>
      {fixedDom ? <div className="lg:w-full w-1/2 h-6 bg-white lg:block hidden" /> : null}
      <div className={`${fixedDom ? 'lg:sticky lg:max-w-full' : 'lg:relative'} fixed top-0 w-full z-10 bg-white`}>
        <ScheduleTourHeader
          fixedDom={fixedDom}
          pageType={pageType}
          list={propertyList}
          selected={selectedProperty?.id ?? ''}
          theme="black"
          showBack={!isReschedule}
          showMenu={false}
          title={`${isReschedule ? 'Reschedule Tour' : ''}`}
          onBack={onFooterBack}
        />
      </div>
      {isMobile ? (
        <div className="pt-[52px] pb-[95px]">
          <SelectedForm
            selectedUnits={selectedUnits}
            property={{
              name: selectedProperty ? selectedProperty.aliasName || selectedProperty.name : 'Unknown',
              address: selectedProperty?.address ?? 'unknown',
            }}
            tourOption={isReschedule ? getTourTypeName(tourType) : ''}
          />

          <ScheduleTourWayAndTime
            isCentralizedStage={isCentralizedStage}
            typeList={tourTypeList}
            type={tourType}
            prospectId={prospectId}
            timeZoneInfo={selectedPropertyTimeZone}
            selectedUnits={selectedUnits}
            selectedProperty={selectedProperty}
            onChangeType={setTourType}
            agentInfo={agentInfo}
            isReschedule={isReschedule}
            onChangeTime={setSelectedAgentTime}
            onBackNewUserId={onBackNewUserId}
            onNoAvailableDay={() => setIsNoAvailableDay(true)}
          />
          <ErrorSnackbar setAddTourError={setErrorMessageOfAddingTour} addTourError={errorMessageOfAddingTour} />
        </div>
      ) : (
        <SubmitTourPc
          property={{
            name: selectedProperty ? selectedProperty.aliasName || selectedProperty.name : 'Unknown',
            address: selectedProperty?.address ?? 'unknown',
            propertyId: selectedProperty?.id ?? '',
          }}
          isCentralizedStage={isCentralizedStage}
          selfGuidTourUnits={selfGuidTourUnits}
          prospectId={prospectId}
          selectedProperty={selectedProperty}
          onChangeTime={setSelectedAgentTime}
          agentInfo={agentInfo}
          onChangeType={setTourType}
          selectedUnits={selectedUnits}
          timeZoneInfo={selectedPropertyTimeZone}
          type={tourType}
          typeList={tourTypeList}
          setPageType={setPageType}
          isReschedule={isReschedule}
          onBackNewUserId={onBackNewUserId}
          onNoAvailableDay={() => setIsNoAvailableDay(true)}
          setAddTourError={setErrorMessageOfAddingTour}
          addTourError={errorMessageOfAddingTour}
        />
      )}
    </>
  )
}

export default SubmitTourPage
